import React, { useState, useEffect } from 'react';
import { parseNfcUrl } from '../utils/Utils';
import HeaderLogo from '../components/HeaderLogo';

function NfcDetails() {
  const [parsedObject, setParsedObject] = useState(null);
  useEffect(() => {
    let url = window.location.href;
    //sample https://tamper-demo.ucreate.identiv.com/tp/?data=UUUUUUUUUUUUUUUUxNNNxOOc
    //localhost test: http://localhost:3000/TP/?data=UUUUUUUUUUUUUUUUxNNNxOOc
    //http://localhost:3000/TP/IDV/?data=UUUUUUUUUUUUUUUUxNNNxOOc

    let parsedObject = parseNfcUrl(url);
    console.log("parse: ", parsedObject)
    setParsedObject(parsedObject)
  }, []);

  return (
    <>
      <HeaderLogo />
      <h2 style={{ textAlign: 'center' }}>Data Info</h2>
      {
        parsedObject &&
        (
          <div style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'left',
          }}>
            <table className='tablenfc'>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>Tag UID</td>
                <td>{parsedObject.tagUID}</td>
              </tr>



              <tr>
                <td>Counter</td>
                <td> {parsedObject.counter}</td>
              </tr>



              <tr>
                <td >Permanent tamper state</td>
                <td> {parsedObject.permanentState}</td>
              </tr>



              <tr >
                <td >Current tamper state</td>
                <td> {parsedObject.currentState}</td>
              </tr>

            </table>
          </div>
        )
      }
    </>

  );
}


export default NfcDetails;