//import { Routes, Route, Link } from "react-router-dom";

function Home() {
    return (
      <>
        <main style={{padding: '10px'}}>
          <h2 style={{ textAlign: 'center' }}>Home page</h2>
        </main>
      </>
    );
  }

  export default Home;