import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import NfcDetails from './pages/NfcDetails';
import './App.css';

const NFC_CHIP_CODE = ['/TP/', '/NC/'];

function resolvePath() {
  let pathname = window.location.pathname;
  console.log('pathname: ', pathname)
  if (!(NFC_CHIP_CODE.includes(pathname.toUpperCase()))) {
    return '*'
  }
}
function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <IdentivSvg style={{ width: '168px', textAlign: 'center' }}></IdentivSvg>
      </header> */}
      <Routes>
        <Route path={resolvePath()} element={<NfcDetails />}>
          <Route path="*" element={<NfcDetails />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
