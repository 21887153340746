import React, { useState, useEffect } from 'react';

import { ReactComponent as IdentivSvg } from '../images/Identiv.svg';


function HeaderLogo() {
    const [customerLogo, setCustomerLogo] = useState("IDV");

    useEffect(() => {
        let pathname = window.location.pathname;
        if (pathname.toUpperCase().includes("RIE")) {
            setCustomerLogo("RIE");
        }

    }, []);

    return (
        <header className="App-header">
            {
                customerLogo === "IDV" && (
                    <IdentivSvg style={{ width: '168px', textAlign: 'center' }}></IdentivSvg>
                )

            }
            {
                customerLogo === "RIE" && (
                    // <RieSvg style={{ width: '168px', height: '100px', textAlign: 'center' }}></RieSvg>
                    <img style={{ height: '100px', textAlign: 'center' }} src={ require('../images/riekelogo.png') } alt='rieke logo' />
                )
            }
        </header>
    );
}
export default HeaderLogo;