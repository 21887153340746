var parse = require('url-parse')

export function parseNfcUrl(url) {
  let urlObj = parse(url, true);
  let query = urlObj.query;
  let data = query.data;
  const myArray = data.split("x");
  let state = myArray.length > 2 ? myArray[2] : null;
  let permanentState = '';
  let currentState = ''
  
  if (state && state.includes("OO")) permanentState = 'Open';
  else if (state && state.includes("CC")) permanentState = 'Closed';
  else if (state && state.includes("00")) permanentState = 'Sealed/Closed';
  else if (state && state.includes("UU")) permanentState = 'Unsealed/Open';
  else if (state && state.includes("RR")) permanentState = 'Re-sealed';
  

  if (state && state.includes('o')) currentState = 'Open';
  else if (state && state.includes('c')) currentState = "Closed"
  return {
    query: query,
    tagUID: myArray[0],
    counter: myArray.length > 1 ? myArray[1] : '',
    state: state,
    permanentState,
    currentState
  }
}